
















































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import { required } from "vuelidate/lib/validators";

export default mixins(MyMixin).extend({
  name: "OrdesVolume",
  props: {
    service: {},
    ordersVolumeOptions: [],
  },
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.ChooseServices.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    loader: false,
    selected: null,
    service: { desc: "", icon: "" },
  }),
  validations: {
    selected: { required },
  },
  methods: {
    submitForm() {
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loader = false;
        return;
      }
      setTimeout(
        () => (
          (this.loader = false),
          // this.$router.push({
          //   name: "choose-plans",
          //   params:{pricing:this.$v.selected.$model}
          // })
          this.$router.push({ name: "leads-thank-you" })
        ),
        1000
      );
    },
  },
});
