import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"leads-wrapper"},[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"","lg":"10","md":"12"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/leads/Logo.svg"),"alt":"logo"}})]),_c(VCard,{staticClass:"leads-card py-5"},[_c('div',{staticClass:"back-btn",on:{"click":function($event){return _vm.$emit('showSelectService')}}},[_c(VIcon,{attrs:{"size":"12"}},[_vm._v("$vuetify.icons.chevronLeftIcon")]),_c('span',[_vm._v("Back")])],1),_c('h3',{staticClass:"mt-lg-4 mb-lg-6"},[_vm._v("WHAT ARE YOU LOOKING FOR?")]),_c(VForm,{staticClass:"w-100 text-center",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"choosen-service d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"choosen-card highlighted-icon"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("$vuetify.icons."+_vm._s(_vm.service.icon))]),_c('span',{domProps:{"textContent":_vm._s(_vm.service.desc)}})],1),_c(VIcon,{attrs:{"size":"18"}},[_vm._v("$vuetify.icons.circlecheckIcon")]),_c('span',{staticClass:"change-service",on:{"click":function($event){return _vm.$emit('showSelectService')}}},[_vm._v("Change Service")])],1),_c('b-form-select',{class:{ is_invalid: _vm.$v.selected.$error },style:({
                color: _vm.selected != null ? '#48BEEA' : '#C6C9CC',
              }),attrs:{"options":_vm.ordersVolumeOptions},on:{"change":function($event){return _vm.$emit('updateOrdersVolume', $event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Choose No. of orders")])]},proxy:true}]),model:{value:(_vm.$v.selected.$model),callback:function ($$v) {_vm.$set(_vm.$v.selected, "$model", $$v)},expression:"$v.selected.$model"}}),(_vm.$v.selected.$error)?_c('div',{staticClass:"errors mt-4 text-center"},[_c(VIcon,{attrs:{"size":"12"}},[_vm._v("$vuetify.icons.exclamationIcon")]),_vm._v(" You have to choose No. of orders to continue ")],1):_vm._e(),_c(VBtn,{staticClass:"next-btn",style:({
                background: _vm.selected != null ? '#101820' : '#C6C9CC',
              }),attrs:{"elevation":"0","loading":_vm.loader},on:{"click":_vm.submitForm},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","color":"white","size":25}})]},proxy:true}])},[_vm._v(" Next "),_c(VIcon,{staticClass:"ml-3",attrs:{"size":"18"}},[_vm._v("$vuetify.icons.doublearrowIcon")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }