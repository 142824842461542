
































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import ChooseOrdersVolume from "./ChooseOrdersVolume.vue";

import { GetServiceOrderVolumeOptions } from "@/integration/get-service-orders-volume-options";
import { SetServiceOrdersVolume } from "@/integration/set-service-orders-volume";

export default mixins(MyMixin).extend({
  name: "Services",
  components: {
    ChooseOrdersVolume,
  },
  mounted() {
    this.trackMyPageView();
    // this.getOptions();
  },

  metaInfo: {
    title: metadata.LeadsServices.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    isShow: false,
    showSelectService: true,
    ordersVolumeOptions: [],
    servicesOptions: [],
    items: [],
    service: {
        phone: "", 
        desc: "",
      },    services: [
      {
        id: 1,
        icon: "homeIcon",
        size: 70,
        desc: "Storage & Warehousing",
      },
      {
        id: 4,
        icon: "warehousIcon",
        size: 70,
        desc: "Warehousing & Delivery",
      },
      {
        id: 3,
        icon: "deliveryIcon",
        size: 90,
        desc: "Delivery",
      },
      {
        id: 7,
        icon: "freightIcon",
        size: 70,
        desc: "Supply Chain / Freight",
      },
      // {
      //   id: 8,
      //   icon: "paymentIcon",
      //   size: 70,
      //   desc: "Payment Services",
      // },
    ],
  }),
  methods: {
    getOptions() {
      GetServiceOrderVolumeOptions.send().then(({ data }) => {
        if (this.service.desc == "Storage & Warehousing") {
          this.ordersVolumeOptions = data.data.cbm_volume;
        } else {
          this.ordersVolumeOptions = data.data.orders_volume;
        }
      });
    },
    handleFocusOut() {
      this.isShow = true;
    },
    updateUserService(service:any) {
      this.logGAEvent(
        "Services",
        service.desc,
        (this.$route.name as string) + " Page"
      );
      SetServiceOrdersVolume.send({
        service_id: service.id,
      });
    },
    updateOrdersVolume(ordesVolume:any) {
      this.logGAEvent(
        "Services",
        ordesVolume,
        (this.$route.name as string) + " Page"
      );
      SetServiceOrdersVolume.send({
        volume: ordesVolume,
      });
    },
    serviceClickListener(service:any) {
      this.getOptions();
      this.service = service;
      this.updateUserService(service);
      this.showSelectService = false;
      this.$router.push({ name: "leads-thank-you" });
    },
  },
});
